import * as React from "react";
import styled from "styled-components";

const MenuBox = (props) => {
  const Div = styled.section`
    padding: 64px 64px 16px 16px;
    height: 190px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    cursor: pointer;
    color: white;
    background: rgba(102, 102, 118, 0.15);
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(102, 102, 118, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
  `;

  const Heading = styled.h3`
    margin: 0;
    font-size: 28px;
    font-weight: 400;
  `;

  const Link = styled.a`
    text-decoration: none;
  `;

  return (
    <Link href={`/${props.link}`}>
      <Div>
        <Heading>{props.title}</Heading>
      </Div>
    </Link>
  );
};

export default MenuBox;

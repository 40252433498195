import * as React from "react";
import MenuBox from "../components/MenuBox";
import Navigation from "../components/Navigation";
import HomeSeo from "../components/SEO/HomeSeo";
import "../styles/style.css";

const IndexPage = () => {
  const sections = [
    { title: "UX Laws and Principles", link: "ux-laws-and-principles" },
    { title: "UX Design Processes", link: "ux-design-processes" },
    { title: "UX Process Templates", link: "ux-process-templates" },
    { title: "Language & Tone", link: "language-and-tone" },
    {
      title: "Writing & Research Templates",
      link: "writing-and-research-templates",
    },
    { title: "Usability Testing", link: "usability-testing" },
  ];

  function slide() {
    var container = document.getElementById("container");
    sideScroll(container, "right", 25, 100, 10);
  }

  function slideBack() {
    var container = document.getElementById("container");
    sideScroll(container, "left", 25, 100, 10);
  }

  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  return (
    <main>
      <div className="content">
        <HomeSeo></HomeSeo>
        <Navigation></Navigation>
        <div className="heading-container">
          <h1>
            All your resource and UX Content Design needs in
            <span className="">one place</span>
          </h1>
        </div>
        <div className="menu-scroll">
          <p>View Menu</p>
          <button className="menu-scroll__button" onClick={() => slideBack()}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/4/4c/Ic_arrow_back_48px.svg"
              alt="left-arrow"
            />
          </button>
          <button className="menu-scroll__button" onClick={() => slide()}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/4/4c/Ic_arrow_back_48px.svg"
              alt="right-arrow"
            />
          </button>
        </div>
        <div id="container" className="menu-boxes">
          {sections.map((section) => (
            <MenuBox title={section.title} link={section.link}></MenuBox>
          ))}
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
